import React, {useEffect, useState} from 'react';
import { Container, Row, Col, Tabs, Tab, Button} from 'react-bootstrap';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Navigation from '../components/Navigation';
import { useLocation } from 'react-router-dom';
import {SlideshowLightbox} from 'lightbox.js-react';
import 'lightbox.js-react/dist/index.css';
import { gallerySeminar, 
        gallaryOrientation, 
        gallaryCpa, 
        gallaryLaunch, 
        gallaryLeap,
        gallaryResult,
        gallaryFeliciation,
        gallaryGuidance,
        gallaryScienceVisit
     } from '../data/gallery';


const GalleryDetails = props => {
    const [key, setKey] = useState('seminar');
    const [activeKey, setActiveKey] = useState();
    let location = useLocation();
    const hashValue = location.hash;
    const newKey = location.hash.slice(1);

    useEffect(() => {
        setActiveKey(newKey);
    }, []); 
    const handleClick = (key) => {
        if(location.hash) {
            window.history.replaceState("", document.title, window.location.pathname);
            setActiveKey(key);
            setKey(key);
       }
       else{
        setKey(key);
        setActiveKey(key);
       }
    }

    const seminarData = gallerySeminar();
    const orientationData = gallaryOrientation();
    const cpaData = gallaryCpa();
    const launchData = gallaryLaunch();
    const leapData = gallaryLeap();
    const resultData = gallaryResult();
    const feliciationData = gallaryFeliciation();
    const guidanceData = gallaryGuidance();
    const scienceVisitData = gallaryScienceVisit();

    return(
        <>
            <div className='header_wrapper'>        
                <Navigation/>                
            </div>
            <section className='breadcrumb_bg'>
                <Container>
                    <Row>
                        <Col sm={12}>
                            <div className='breadcrumb_content'>
                                <h1>Gallery Details</h1>
                                <Breadcrumb>
                                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                    <Breadcrumb.Item href="/gallery">Gallery</Breadcrumb.Item>
                                    <Breadcrumb.Item active>Gallery Details</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section  data-aos="fade-up" data-aos-duration="3000">
                <Container>                 
                    <Tabs
                        defaultActiveKey='seminar'
                        id="controlled-tab-example"
                        className="mt-50 horizontal_tabs"
                        activeKey={activeKey}
                        onSelect={(key) => handleClick()}
                        justify
                        >
                            
                            <Tab eventKey="seminar" title="seminar">
                                <Tabs
                                    defaultActiveKey="0"
                                    id="uncontrolled-tab-example"
                                    className="mb-3 theme_tabs sub_tabs justify-content-center mt-5"
                                    >
                                        {seminarData.map((name, i) => ( 
                                            <Tab eventKey={i} title={name.type} key={i}> 
                                                <Tabs
                                                    defaultActiveKey="0"
                                                    id="sub-tabs"
                                                    className="mb-3 theme_tabs sub_tabs justify-content-center"
                                                    >
                                                        {name.subtype.map((sub, ind) => (
                                                        <Tab eventKey={ind} title={sub.year} key={ind}>
                                                            <SlideshowLightbox className='image_grid'>
                                                                {sub.images.map((img, indx) => (
                                                                    <img src={img} key={indx} />
                                                                ))}
                                                            </SlideshowLightbox>
                                                        </Tab>
                                                    ))}
                                                </Tabs>                                               
                                            </Tab>                                       
                                        ))}                                        
                                </Tabs>
                            </Tab>                            
                            <Tab eventKey="pre-orientation" title="Pre Orientation">
                                <Tabs
                                    defaultActiveKey="0"
                                    id="uncontrolled-tab-example"
                                    className="mb-3 theme_tabs sub_tabs justify-content-center mt-5"
                                    >
                                        {orientationData.map((name, i) => ( 
                                            <Tab eventKey={i} title={name.type} key={i}> 
                                                <Tabs
                                                    defaultActiveKey="0"
                                                    id="sub-tabs"
                                                    className="mb-3 theme_tabs sub_tabs justify-content-center"
                                                    >
                                                        {name.subtype.map((sub, ind) => (
                                                        <Tab eventKey={ind} title={sub.year} key={ind}>
                                                            <SlideshowLightbox className='image_grid'>
                                                                {sub.images.map((img, indx) => (
                                                                    <img src={img} key={indx} />
                                                                ))}
                                                            </SlideshowLightbox>
                                                        </Tab>
                                                    ))}
                                                </Tabs>                                               
                                            </Tab>                                       
                                        ))}                                        
                                </Tabs>
                            </Tab>
                            <Tab eventKey="cpa" title="CPA">
                                <Tabs
                                    defaultActiveKey="0"
                                    id="uncontrolled-tab-example"
                                    className="mb-3 theme_tabs sub_tabs justify-content-center mt-5"
                                    >
                                        {cpaData.map((name, i) => ( 
                                            <Tab eventKey={i} title={name.type} key={i}> 
                                                <Tabs
                                                    defaultActiveKey="0"
                                                    id="sub-tabs"
                                                    className="mb-3 theme_tabs sub_tabs justify-content-center"
                                                    >
                                                        {name.subtype.map((sub, ind) => (
                                                        <Tab eventKey={ind} title={sub.year} key={ind}>
                                                            <SlideshowLightbox className='image_grid'>
                                                                {sub.images.map((img, indx) => (
                                                                    <img src={img} key={indx} />
                                                                ))}
                                                            </SlideshowLightbox>
                                                        </Tab>
                                                    ))}
                                                </Tabs>                                               
                                            </Tab>                                       
                                        ))}                                        
                                </Tabs>
                            </Tab>
                            <Tab eventKey="launch" title="Launch">
                                <Tabs
                                    defaultActiveKey="0"
                                    id="uncontrolled-tab-example"
                                    className="mb-3 theme_tabs sub_tabs justify-content-center mt-5"
                                    >
                                        {launchData.map((name, i) => ( 
                                            <Tab eventKey={i} title={name.type} key={i}> 
                                                <Tabs
                                                    defaultActiveKey="0"
                                                    id="sub-tabs"
                                                    className="mb-3 theme_tabs sub_tabs justify-content-center"
                                                    >
                                                        {name.subtype.map((sub, ind) => (
                                                        <Tab eventKey={ind} title={sub.year} key={ind}>
                                                            <SlideshowLightbox className='image_grid'>
                                                                {sub.images.map((img, indx) => (
                                                                    <img src={img} key={indx} />
                                                                ))}
                                                            </SlideshowLightbox>
                                                        </Tab>
                                                    ))}
                                                </Tabs>                                               
                                            </Tab>                                       
                                        ))}                                        
                                </Tabs>
                            </Tab>
                            <Tab eventKey="leap-workshop" title="Leap Workshop">
                                <Tabs
                                    defaultActiveKey="0"
                                    id="uncontrolled-tab-example"
                                    className="mb-3 theme_tabs sub_tabs justify-content-center mt-5"
                                    >
                                        {leapData.map((name, i) => ( 
                                            <Tab eventKey={i} title={name.type} key={i}> 
                                                <Tabs
                                                    defaultActiveKey="0"
                                                    id="sub-tabs"
                                                    className="mb-3 theme_tabs sub_tabs justify-content-center"
                                                    >
                                                        {name.subtype.map((sub, ind) => (
                                                        <Tab eventKey={ind} title={sub.year} key={ind}>
                                                            <SlideshowLightbox className='image_grid'>
                                                                {sub.images.map((img, indx) => (
                                                                    <img src={img} key={indx} />
                                                                ))}
                                                            </SlideshowLightbox>
                                                        </Tab>
                                                    ))}
                                                </Tabs>                                               
                                            </Tab>                                       
                                        ))}                                        
                                </Tabs>
                            </Tab>
                            <Tab eventKey="result" title="Result">
                                <Tabs
                                    defaultActiveKey="0"
                                    id="uncontrolled-tab-example"
                                    className="mb-3 theme_tabs sub_tabs justify-content-center mt-5"
                                    >
                                        {resultData.map((name, i) => ( 
                                            <Tab eventKey={i} title={name.type} key={i}> 
                                                <Tabs
                                                    defaultActiveKey="0"
                                                    id="sub-tabs"
                                                    className="mb-3 theme_tabs sub_tabs justify-content-center"
                                                    >
                                                        {name.subtype.map((sub, ind) => (
                                                        <Tab eventKey={ind} title={sub.year} key={ind}>
                                                            <SlideshowLightbox className='image_grid'>
                                                                {sub.images.map((img, indx) => (
                                                                    <img src={img} key={indx} />
                                                                ))}
                                                            </SlideshowLightbox>
                                                        </Tab>
                                                    ))}
                                                </Tabs>                                               
                                            </Tab>                                       
                                        ))}                                        
                                </Tabs>
                            </Tab>
                            <Tab eventKey="feliciation" title="Feliciation">
                                <Tabs
                                    defaultActiveKey="0"
                                    id="uncontrolled-tab-example"
                                    className="mb-3 theme_tabs sub_tabs justify-content-center mt-5"
                                    >
                                        {feliciationData.map((name, i) => ( 
                                            <Tab eventKey={i} title={name.type} key={i}> 
                                                <Tabs
                                                    defaultActiveKey="0"
                                                    id="sub-tabs"
                                                    className="mb-3 theme_tabs sub_tabs justify-content-center"
                                                    >
                                                        {name.subtype.map((sub, ind) => (
                                                        <Tab eventKey={ind} title={sub.year} key={ind}>
                                                            <SlideshowLightbox className='image_grid'>
                                                                {sub.images.map((img, indx) => (
                                                                    <img src={img} key={indx} />
                                                                ))}
                                                            </SlideshowLightbox>
                                                        </Tab>
                                                    ))}
                                                </Tabs>                                               
                                            </Tab>                                       
                                        ))}                                        
                                </Tabs>
                            </Tab>
                            <Tab eventKey="guidance-session" title="Guidance Session">
                                <Tabs
                                    defaultActiveKey="0"
                                    id="uncontrolled-tab-example"
                                    className="mb-3 theme_tabs sub_tabs justify-content-center mt-5"
                                    >
                                        {guidanceData.map((name, i) => ( 
                                            <Tab eventKey={i} title={name.type} key={i}> 
                                                <Tabs
                                                    defaultActiveKey="0"
                                                    id="sub-tabs"
                                                    className="mb-3 theme_tabs sub_tabs justify-content-center"
                                                    >
                                                        {name.subtype.map((sub, ind) => (
                                                        <Tab eventKey={ind} title={sub.year} key={ind}>
                                                            <SlideshowLightbox className='image_grid'>
                                                                {sub.images.map((img, indx) => (
                                                                    <img src={img} key={indx} />
                                                                ))}
                                                            </SlideshowLightbox>
                                                        </Tab>
                                                    ))}
                                                </Tabs>                                               
                                            </Tab>                                       
                                        ))}                                        
                                </Tabs>
                            </Tab>
                            <Tab eventKey="science-visit" title="Science Visit">
                                <Tabs
                                    defaultActiveKey="0"
                                    id="uncontrolled-tab-example"
                                    className="mb-3 theme_tabs sub_tabs justify-content-center mt-5"
                                    >
                                        {scienceVisitData.map((name, i) => ( 
                                            <Tab eventKey={i} title={name.type} key={i}> 
                                                <Tabs
                                                    defaultActiveKey="0"
                                                    id="sub-tabs"
                                                    className="mb-3 theme_tabs sub_tabs justify-content-center"
                                                    >
                                                        {name.subtype.map((sub, ind) => (
                                                        <Tab eventKey={ind} title={sub.year} key={ind}>
                                                            <SlideshowLightbox className='image_grid'>
                                                                {sub.images.map((img, indx) => (
                                                                    <img src={img} key={indx} />
                                                                ))}
                                                            </SlideshowLightbox>
                                                        </Tab>
                                                    ))}
                                                </Tabs>                                               
                                            </Tab>                                       
                                        ))}                                        
                                </Tabs>
                            </Tab>
                    </Tabs>
                </Container>
            </section>
        </>
    );
};

export default GalleryDetails;