import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import { Helmet } from 'react-helmet';

import Navigation from '../components/Navigation';

import aboutImg from '../assets/images/NagpurHO.png';
import CeoImg from '../assets/images/ceo-new.jpg';

import core1 from '../assets/images/icons/reading.png';
import core2 from '../assets/images/icons/education.png';
import core3 from '../assets/images/icons/light-bulb.png';
import core4 from '../assets/images/icons/transparency.png';
import core5 from '../assets/images/icons/profit.png';

import mentor1 from '../assets/images/mentors/Sarang-sir-Mentors.jpeg';
import mentor2 from '../assets/images/mentors/Swapnil-Upganlawar.jpg';
import mentor3 from '../assets/images/mentors/anil-kayande.jpg';
import mentor4 from '../assets/images/mentors/Sudhir-Bajpai.jpeg';
import mentor5 from '../assets/images/mentors/Kishore-Kumar.jpg';
import mentor6 from '../assets/images/mentors/Amit-Singh.jpg';
import mentor7 from '../assets/images/mentors/Akshay-Sharma.jpg';
import mentor8 from '../assets/images/mentors/Tinu-Agrawal.jpeg';




import Breadcrumb from 'react-bootstrap/Breadcrumb';


const About = props => {
    return (
        <>
            <Helmet>
                <title>About Us - ICAD Study Center</title>
                <meta name="description" content="Learn more about us and our mission." />
                <meta name="keywords" content="ICAD Nagpur, founder Sarang Upganlawar, JEE preparation philosophy, education innovation" />
            </Helmet>
      
            <div className='header_wrapper'>        
                <Navigation/>                
            </div>
            <section className='breadcrumb_bg'>
                <Container>
                    <Row>
                        <Col sm={12}>
                            <div className='breadcrumb_content'>
                                <h1>About Us</h1>
                                <Breadcrumb>
                                    <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                    {/* <Breadcrumb.Item href="">
                                        Library
                                    </Breadcrumb.Item> */}
                                    <Breadcrumb.Item active>About Us</Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            
            <section className='mt-50' data-aos="fade-right" data-aos-duration="3000" id='about-us'>
                <Container>
                    <Row>
                        <Col lg={6} sm={12}>
                            <div>
                                <h2 className='title'>About ICAD – Shaping Future Leaders</h2>
                                <p>Since our founding, ICAD has been dedicated to simplifying the complexities of JEE and NEET 
                                preparation. Through innovative teaching methods, personalized mentorship, and a passion 
                                for education, we’ve helped thousands of students achieve success. </p>
                                <h2 className='subtitle mb-0'>our vision</h2>
                                <p>To become a leading name in the field of competitive exam coaching by making high
                                quality education accessible and effective for all.</p>
                                <h2 className='subtitle mb-0'>our Mission</h2>
                                <p className='mb-1'>At ICAD, our mission is simple:</p>
                                <p>To simplify the process of JEE and NEET preparation while delivering personalized 
                                education to every student.</p>
                            </div>
                        </Col>
                        <Col lg={6} sm={12}>
                            <Image src={aboutImg} alt="about image" className='d-block mx-auto' fluid />
                        </Col>                        
                    </Row>
                    <Row>
                        <Col sm={12} className='text-center mt-5' data-aos="fade-up" data-aos-duration="3000">
                            <h2 className='subtitle'>The ICAD Journey</h2>
                            <p>Founded over two decades ago by Sarang Upganlawar, ICAD has grown from a small 
                            coaching center to one of the most respected institutions in Vidarbha. We are now set to 
                            spread our wings in Nashik, Nanded and Sambhaji Nagar after establishing our credibility in 
                            Nagpur, Yavatmal, Chandrapur and Amravati. With each passing year, we’ve developed new 
                            teaching tools like our Command Capsules and Digital Classes, staying at the cutting edge of 
                            education. </p>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className='mt-50 bg_light_gray' data-aos="fade-up" data-aos-duration="3000" id='ceo-message'>
                <Container>                    
                    <Row>
                        <Col lg={3} md={12} sm={12} className='order-lg-first order-md-second mt-md-3'>
                            <h2 className='title'>Meet Our Founder</h2>
                            <h3>- Sarang Upganlawar</h3>  
                        </Col>
                        <Col lg={5} md={12} sm={12} className='order-lg-second order-md-first'>
                            <Image src={CeoImg} alt="about image" className='img_radius d-block mx-auto' fluid />
                        </Col>
                        <Col lg={4} md={12} sm={12} className='order-lg-last order-md-last align-self-lg-end mt-xs-3'>                              
                            <div><p><b>Sarang Upganlawar</b> is an educator, visionary, and mentor to thousands of students. </p>     
                            <p className='mb-0'>With a passion for simplifying education and a research-driven approach, he has led ICAD to where 
                            it is today.</p>                           
                            </div>
                        </Col>                        
                    </Row>                    
                </Container>               
            </section>            

            <section className='mt-50' data-aos="fade-up" data-aos-duration="3000" id='about-mentors'>
                <Container>
                    <h2 className='subtitle mb-30'>Mentors</h2>
                    <Row>
                        <Col lg={3} md={4} lg={3} md={4} sm={3} className='mb-15'>
                            <Card className='info-card'>
                                <Card.Body>
                                    <Image src={mentor1} alt='mentor-1' fluid />
                                    <Card.Title>Mr. sarang Upganlawar</Card.Title>                                    
                                </Card.Body>
                                <div className='hover-text'>
                                    <Card.Title>Mr. sarang Upganlawar</Card.Title>
                                    <p>M.PHARM, MBA</p>
                                    <p>FOUNDER & CEO</p>
                                </div>
                            </Card>
                        </Col>
                        <Col lg={3} md={4} sm={3} className='mb-15'>
                            <Card className='info-card'>
                                <Card.Body>
                                    <Image src={mentor2} alt='mentor-2' fluid />
                                    <Card.Title>Mr. Swapnil Upganlawar</Card.Title>
                                </Card.Body>
                                <div className='hover-text'>
                                    <Card.Title>Mr. Swapnil Upganlawar</Card.Title>
                                    <p>MS (Computer Science, CMU, USA)</p>
                                    <p>MBA (Wharton Business School, USA)</p>
                                    <p>Senior Manager, AWS AI, Amazon Web Services, USA</p>
                                </div>
                            </Card>
                        </Col>
                        <Col lg={3} md={4} sm={3} className='mb-15'>
                            <Card className='info-card'>
                                <Card.Body>
                                    <Image src={mentor3} alt='mentor-3' fluid />
                                    <Card.Title>Dr. anil kayande</Card.Title>
                                </Card.Body>
                                <div className='hover-text'>
                                    <Card.Title>Dr. anil kayande</Card.Title>
                                    <p>(M.Sc. (Mathematics), Ph.D.)</p>
                                    <p>Faculty Positions</p>
                                    <p>University of Alberta, Canada</p>
                                    <p>University of Rhode Island, RI, USA,</p>
                                    <p>University of Lfe, Nigeria</p>
                                    <p>Former Principal</p>
                                    <p>Ramdeobaba Engg. Col. Nagpur</p>
                                </div>
                            </Card>
                        </Col>
                        <Col lg={3} md={4} sm={3} className='mb-15'>
                            <Card className='info-card'>
                                <Card.Body>
                                    <Image src={mentor4} alt='mentor-4' fluid />
                                    <Card.Title>Mr. Sudhir Kumar</Card.Title>
                                </Card.Body>
                                <div className='hover-text'>
                                    <Card.Title>Mr. Sudhir Kumar</Card.Title>
                                    <p>M.PHIL</p>
                                    <p>14 YEAR EXPERIENCE</p>
                                    <p>ACADAMIC HEAD - JEE & NEET</p>
                                    <p>HEAD OF DEPARTMENT (MATHS)</p>
                                </div>
                            </Card>
                        </Col>                    
                        <Col lg={3} md={4} sm={3} className='mb-15'>
                            <Card className='info-card'>
                                <Card.Body>
                                    <Image src={mentor5} alt='mentor-5' fluid />
                                    <Card.Title>Mr. kishore kumar katta</Card.Title>                                    
                                </Card.Body>
                                <div className='hover-text'>
                                    <Card.Title>Mr. kishore kumar katta</Card.Title>
                                    <p>M.Sc. - Physics</p>
                                    <p>22 Years in JEE (Advanced) & Physics Olympiad Training</p>
                                    <p>Head of Department (Physics)</p>
                                </div>
                            </Card>
                        </Col>
                        <Col lg={3} md={4} sm={3} className='mb-15'>
                            <Card className='info-card'>
                                <Card.Body>
                                    <Image src={mentor6} alt='mentor-6' fluid />
                                    <Card.Title>Mr. amit singh</Card.Title>
                                </Card.Body>
                                <div className='hover-text'>
                                    <Card.Title>Mr. amit singh</Card.Title>
                                    <p>M.SC</p>
                                    <p>21 YEAR EXPERIENCE</p>
                                    <p>Head of Department (Chemistry)</p>
                                </div>
                            </Card>
                        </Col>
                        <Col lg={3} md={4} sm={3} className='mb-15'>
                            <Card className='info-card'>
                                <Card.Body>
                                    <Image src={mentor7} alt='mentor-7' fluid />
                                    <Card.Title>Mr. Akshay sharma</Card.Title>
                                </Card.Body>
                                <div className='hover-text'>
                                    <Card.Title>Mr. Akshay sharma</Card.Title>
                                    <p>M.Sc</p>
                                    <p>14 YEAR EXPERIENCE</p>
                                    <p>Academic Centre Head (NEET)</p>
                                    <p>Head of Department - (Biology)</p>
                                </div>
                            </Card>
                        </Col>
                        <Col lg={3} md={4} sm={3} className='mb-15'>
                            <Card className='info-card'>
                                <Card.Body>
                                    <Image src={mentor8} alt='mentor-8' fluid />
                                    <Card.Title>Dr. tinu agrawal</Card.Title>
                                </Card.Body>
                                <div className='hover-text'>
                                    <Card.Title>Dr. tinu agrawal</Card.Title>
                                    <p>ICAD Research and Development Head</p>
                                    <p>25 years experience</p>
                                    <p>BE (Civil and Applied Mechanics),</p>
                                    <p>MBA, PhD in Operations Management,</p>
                                    <p>70+ Research Papers in esteemed Publications and Journals.</p>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className='mt-50 bg_light_gray' data-aos="fade-up" data-aos-duration="3000">
                <Container>
                    <Row>
                        <Col md={6} sm={12} className='mb-small'>
                            <h2 className='subtitle'>Our Teaching Philosophy</h2>
                            <p>At ICAD, we believe that every student is unique, which is why our teaching philosophy 
                            focuses on: </p>
                            <ul className='check_list'>
                                <li>Personalized Attention: Study plans for every student and a dedicated batch mentor for 
                                each one.</li>
                                <li>Conceptual Learning: Emphasizing core concepts to build a strong foundation.</li>
                                <li>Continuous Improvement: Regular tests, assessments, and feedback to help students 
                                excel.</li>
                            </ul>
                        </Col>
                        <Col md={6} sm={12} className='text-center align-self-xl-center align-self-lg-center align-self-md-center'>
                            <Button className="theme_btn">Learn More About Our Approach</Button>
                            <Button className="theme_btn mt-4">Join Our Community of Future Leaders</Button>
                        </Col>
                    </Row>
                </Container>
            </section>            

            <section className='mt-50' data-aos="fade-up" data-aos-duration="3000" id='core-values'>
                <Container>
                    <h2 className='title mb-30'>core values</h2>
                    <div className='core_cards'>
                        <Card>
                            <Card.Body>
                                <Image src={core1} alt="core value image" fluid />
                                <Card.Title>students first</Card.Title>
                            </Card.Body>
                        </Card>
                        <Card>
                            <Card.Body>
                                <Image src={core2} alt="core value image" fluid />
                                <Card.Title>academic excellence</Card.Title>
                            </Card.Body>
                        </Card>
                        <Card>
                            <Card.Body>
                                <Image src={core3} alt="core value image" fluid />
                                <Card.Title>process and product innovation</Card.Title>
                            </Card.Body>
                        </Card>
                        <Card>
                            <Card.Body>
                                <Image src={core4} alt="core value image" fluid />
                                <Card.Title>transparency</Card.Title>
                            </Card.Body>
                        </Card>
                        <Card>
                            <Card.Body>
                                <Image src={core5} alt="core value image" fluid />
                                <Card.Title>employee welfare</Card.Title>
                            </Card.Body>
                        </Card>
                    </div>
                </Container>
            </section>
        </>
    );
};

export default About;