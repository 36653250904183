import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import logo from '../assets/images/logo.png';

import facebookIcon from '../assets/images/icons/facebook.svg';
import twitIcon from '../assets/images/icons/twitter.svg';
import blogIcon from '../assets/images/icons/blog.svg';
import ytubeIcon from '../assets/images/icons/youtube.svg';
import instaIcon from '../assets/images/icons/instagram.svg';

const Footer = props => {
  return (
    <div className="footer mt-50">
      <Container>
        <Row>
            <Col lg="4" sm="12">
                <div>
                    <img src={logo} alt="Logo" className='logo'/>
                    <h4 className='text-uppercase head'>Nurturing Talent Since 1999</h4>
                    {/* <h6>© 2024, All Rights Reserved.</h6>
                    <h6>ICAD TECHNOLOGIES PVT. LTD.</h6> */}
                </div>
                <div className='social_icon mb-3'>
                    <span className='footer_heading'>Follow Us On :</span>
                    <ul>
                        <li><a href='https://www.facebook.com/jee.icad/' target="_blank"><img src={facebookIcon} className='svg_img'/></a></li>
                        <li><a href='https://twitter.com/#!/ICADSL' target="_blank"><img src={twitIcon} className='svg_img'/></a></li>
                        <li><a href='https://www.youtube.com/channel/UCm2p1O0DA9Xgv18pUe7Juew?feature=watch' target="_blank"><img src={ytubeIcon} className='svg_img'/></a></li>
                        <li><a href='http://icadsl.blogspot.com/' target="_blank"><img src={blogIcon} className='svg_img'/></a></li>
                        <li><a href='https://www.instagram.com/icadsl/?utm_source=ig_profile_share&amp;igshid=1m6rhkv66v0yd' target="_blank"><img src={instaIcon} className='svg_img'/></a></li>
                    </ul>
                </div>
            </Col>
            <Col lg="4" sm="12">
                <div className='mb-30'>
                    <h1 className='footer_heading'>Corporate Head Office & Study Centre</h1>
                    <p>ICAD Heights (West Nagpur) 21, Tilak Nagar,<br/>Opp. Basket Ball Ground<br/>Nagpur - 440010,
                    <br/>Maharashtra State, India</p>
                </div>
                <div className='mb-30'>
                    <h1 className='footer_heading'>Contact</h1>
                    <p className='mb-0'>+91-9355870236, +91-9355870232</p>
                    <p>contact@icadiit.com</p>
                </div>
                <div className='mb-30'>
                    <h1 className='footer_heading'>Quick links</h1>
                    <a href='/privacy-policy' className='footer_link'>Privacy Policy</a>
                    <a href='/terms-and-conditions' className='footer_link'>terms and conditions</a>
                    <a href='/refund-and-cancellation-policy'className='footer_link'>refund and cancellation policy</a>
                </div>
            </Col>
            <Col lg="4" sm="12">
                <div>
                    <h1 className='footer_heading'>OTHER STUDY CENTERS</h1> 
                </div>
                <div className='mb-30'>
                    <h1 className='footer_heading'>nagpur</h1> 
                    <a href='/contact-us' className='footer_link'>Dharmapeth NEET Center</a>
                    <a href='/contact-us' className='footer_link'>Hanumar Nagar Center</a>
                    <a href='/contact-us'className='footer_link'>Sadar center</a>
                    <a href='/contact-us'className='footer_link'>chatrapati nagar center</a>
                    <a href='/contact-us'className='footer_link'>recidential campus</a>
                </div>
                <div className='mb-30'>
                    <h1 className='footer_heading'>amravati</h1> 
                    <a href='/contact-us' className='footer_link'>training Center</a>
                </div>
                <div className='mb-30'>
                    <h1 className='footer_heading'>chandrapur</h1> 
                    <a href='/contact-us' className='footer_link'>training Center</a>
                </div>
                <div className='mb-30'>
                    <h1 className='footer_heading'>yavatmal</h1> 
                    <a href='/contact-us' className='footer_link'>training Center</a>
                </div>
            </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Footer;