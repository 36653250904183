import React, {useState, useEffect} from 'react';
import {Row, Col, Button} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import emailjs from 'emailjs-com';

// import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { statesWithCities } from '../data/location';

const ContactForm = props => {
    const statesWithCitiesData = statesWithCities();

    const [selectedState, setSelectedState] = useState('');
    const [cities, setCities] = useState([]); 
    const [captcha, setCaptcha] = useState(''); 
    const [errors, setErrors] = useState({});
    const [success, setSuccess] = useState(false);
    
    const [formData, setFormData] = useState({ 
        name: '', 
        email: '',
        mobile: '',
        captcha: '',
        states: '',
        citys: '',
        class: '',
        course: '',
        center: '',
        consent:''
    });

    // state select change
    const handleStateChange = (e) => {
        const stateName = e.target.value;
        setSelectedState(stateName);

        const state = statesWithCitiesData.find(state => state.name === stateName);
        setCities(state ? state.cities : []);

        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value});
    };

     // Generate a random CAPTCHA string
    const generateCaptcha = () => {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < 6; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        setCaptcha(result);
    };

    useEffect(() => {
        generateCaptcha(); // Generate CAPTCHA on component mount
    }, []);

    const loadCaptha = () => {
        generateCaptcha();
    }


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value});
    };

    const validate = () => {
        const newErrors = {};
        if (!formData.name) newErrors.name = 'Name is required';
        if (!formData.mobile) {
            newErrors.mobile = 'Contact number is required';
          }
          // Check if it is a valid format (example: 10 digits)
          else if (!/^\d{10}$/.test(formData.mobile)) {
            newErrors.mobile = 'Contact number must be 10 digits';
          }   
          if (!formData.email) {
            newErrors.email = 'Email is required';
          }
          else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = 'Email is invalid';
          }   
        if (formData.captcha !== captcha || !formData.captcha) newErrors.captcha = 'CAPTCHA does not match. Please try again';
        if (!selectedState) newErrors.states = 'Please select state';
        if (!formData.citys) newErrors.citys = 'Please select city';
        if (!formData.class) newErrors.class = 'Please select Class';
        if (!formData.course) newErrors.course = 'Please select Course';
        if (!formData.center) newErrors.center = 'Please select Center';
        if (!formData.consent) newErrors.consent = 'Please tick consent checkbox to further process';
        return newErrors;
      };
    
      const handleSubmit = (e) => {
        e.preventDefault(); // Prevent default form submission
        const validationErrors = validate();
        if (Object.keys(validationErrors).length > 0) {
          setErrors(validationErrors);
        } else {          
          console.log('Form submitted successfully:', formData); // This is where the submission logic goes
          emailjs.send('service_1l5n4t8', 'template_af5saz9', formData, 'khP-Wp7AckcNLRvuY')
            .then((response) => {
            console.log('Email sent successfully!', response.status, response.text);
            setSuccess(true);
            setFormData({ 
                name: '', 
                email: '',
                mobile: '',
                states: '',
                citys: '',
                class: '',
                course: '',
                center: ''

            }); // Reset form
            setErrors({});
            // setFormData(formData);
            })
            .catch((error) => {
                 console.error('Error sending email:', error);
                 setErrors({});
            });
        }
    };

    return(
        <>
           <Form onSubmit={handleSubmit}>
                <div className='mb-3'>
                    <input
                        type="text"
                        name="name"
                        placeholder="Enter name"
                        value={formData.name}
                        onChange={handleChange}
                        className='form-control'
                        />
                        {errors.name && <span style={{ color: 'red' }}>{errors.name}</span>}
                </div>
                <div className='mb-3'>
                    <input
                        type="email"
                        name="email"
                        placeholder="Enter email"
                        value={formData.email}
                        onChange={handleChange}
                        className='form-control'
                        />
                        {errors.email && <span style={{ color: 'red' }}>{errors.email}</span>}
                </div>
                <div className='mb-3'>
                    <input
                        type="text"
                        name="mobile"
                        placeholder="Enter mobile Number"
                        value={formData.mobile}
                        onChange={handleChange}
                        className='form-control'
                        />
                        {errors.mobile && <span style={{ color: 'red' }}>{errors.mobile}</span>}
                </div>
                <div className='mb-3'>
                    <select
                        name="states"
                        value={selectedState}
                        onChange={handleStateChange}
                        className='form-control'
                        >
                        <option value="">Select a state</option>
                        {statesWithCitiesData.map((state) => (
                            <option key={state.name} value={state.name}>
                                {state.name}
                            </option>
                        ))}
                    </select>
                    {errors.states && <span style={{ color: 'red' }}>{errors.states}</span>}
                </div>
                {cities.length > 0 && (
                <div className='mb-3'>
                    <select
                        name="citys"
                        value={formData.city}
                        onChange={handleChange}
                        className='form-control'
                        >
                        <option value="">Select a city</option>
                        {cities.map((city) => (
                            <option key={city} value={city}>
                            {city}
                            </option>
                        ))}
                    </select>
                    {errors.citys && <span style={{ color: 'red' }}>{errors.citys}</span>}
                </div>
                )}
                <div className='mb-3'>
                    <select
                        name="class"
                        value={formData.class}
                        onChange={handleChange}
                        className='form-control'
                        >
                        <option>Select Class Studying In</option>
                        <option value="1">VII</option>
                        <option value="2">VIII</option>
                        <option value="3">IX</option>
                        <option value="4">X</option>
                        <option value="5">XI</option>
                        <option value="6">XII</option>     
                    </select>
                    {errors.class && <span style={{ color: 'red' }}>{errors.class}</span>}
                </div>
                <div className='mb-3'>
                    <select
                        name="course"
                        value={formData.course}
                        onChange={handleChange}
                        className='form-control'
                        >
                        <option>Select Course Interested In</option>
                        <option value="1">VIII</option>
                        <option value="2">IX</option>
                        <option value="3">X</option>
                        <option value="4">11 & 12 JEE</option>
                        <option value="5">11 & 12 NEET</option>
                        <option value="6">12th JEE</option>
                        <option value="7">12th NEET</option>
                        <option value="8">JEE Repeater</option>
                        <option value="9">NEET Repeater</option>
                        <option value="10">11 & 12 (JEE main & MHT-CET)</option>  
                    </select>
                    {errors.course && <span style={{ color: 'red' }}>{errors.course}</span>}
                </div>
                <div className='mb-3'>
                    <select
                        name="center"
                        value={formData.center}
                        onChange={handleChange}
                        className='form-control'
                        >
                        <option>Select Prefered Center</option>
                        <option value="tilak nagar">Tilak Nagar</option>
                        <option value="sadar">Sadar</option>
                        <option value="hanumar nagar">Hanuman Nagar</option>      
                        <option value="chatrapati nagar">Chatrapati Nagar</option> 
                        <option value="nagpur">Nagpur Residential Campus</option>    
                        <option value="amravati">Amravati</option>    
                        <option value="chandrapur">Chandrapur</option>   
                        <option value="yavatmal">Yavatmal</option>    
                        <option value="aurangabad">Aurangabad</option> 
                        <option value="nanded">Nanded</option>
                        <option value="nashik">Nashik</option>    
                    </select>
                    {errors.center && <span style={{ color: 'red' }}>{errors.center}</span>}
                </div>
                <div className='mb-3'>
                    <Row>
                        <Col sm="6">
                            <div className='captcha_div form-control'>
                                <span>{captcha}</span>
                                <Button onClick={loadCaptha} className='captchaRefreshBtn'>Refresh</Button>
                            </div>                        
                        </Col>
                        <Col sm="6">
                            <input type="text" className="form-control" name="captcha" value={formData.captcha} onChange={handleChange} placeholder="Enter text as shown" />
                        </Col>
                        {errors.captcha && <span style={{ color: 'red' }}>{errors.captcha}</span>}
                    </Row>                        
                </div>
                <div className='mb-3'>
                    <label className="form-check-label">
                        <input type="checkbox" className='form-check-input' name="consent"
                            value={formData.consent}
                            onChange={handleChange} />
                        &nbsp;&nbsp;I agree to give my consent to receive updates through SMS/Email & WhatsApp*
                    </label>
                    {errors.consent && <span style={{ color: 'red' }}>{errors.consent}</span>}
                </div>


                <Button className='theme_btn d-block mx-auto' type="submit" size='lg'>
                    Submit
                </Button>
           </Form>
        </>
    );
};
    
export default ContactForm;


