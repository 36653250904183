import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import { Helmet } from 'react-helmet';

import cpaMain from '../assets/images/cpa1.png';
import cpaFeature from '../assets/images/cpa2.jpeg';
import benefits1 from '../assets/images/icons/benefits/1.png';
import benefits2 from '../assets/images/icons/benefits/2.png';
import benefits3 from '../assets/images/icons/benefits/3.png';
import benefits4 from '../assets/images/icons/benefits/4.png';
import benefits5 from '../assets/images/icons/benefits/5.png';

const IcadCpa = props => {
    return (
        <>
            <Helmet>
                <title>ICAD CPA</title>
                <meta name="description" content="Your CPA Dream, Our Support." />
                <meta name="keywords" content="ICAD CPA, ICAD Scholarship" />
            </Helmet>     
            
            <section className='bg_light_gray'>
                <Container>
                    <Row>
                        <Col sm={12} className='text-center'>
                            <div className='mb-5' data-aos="fade-up" data-aos-duration="3000" data-aos-anchor-placement="top-center">
                                <h1 className='title cpa_title mb-3'>"Your CPA Dream, Our Support<br/>Win Up to 90% Scholarship Today!"</h1>
                                <h2 className='cpa_subtitle'>Kickstart your CPA journey with unmatched guidance, expert resources, <br/>and scholarships that make success affordable.</h2>
                            </div>
                            <div data-aos="zoom-in-up" data-aos-duration="3000" data-aos-anchor-placement="top-center">
                                <Image src={cpaMain} alt='CPA image' className='cpa_main_img' fluid></Image>
                            </div>
                        </Col>                        
                    </Row>
                </Container>
            </section>
            <section className='mt-50'>
                <Container>
                    <Row>
                        <Col sm={12} className='text-center'>
                            <div className='mb-5' data-aos="fade-up" data-aos-duration="3000" data-aos-anchor-placement="top-center">
                                <h1 className='title mb-3'>Key Benefits</h1>
                                <h2 className='cpa_subtitle'>Here some our multitude of Key Benefits, <br/>empowering you to achieve your goals.</h2>
                            </div>                            
                        </Col>                        
                    </Row>
                    <Row className='benifits_row d-flex'>
                        <Col lg={4} md={12} sm={12} className=''>
                            <div>
                                <div className='service text-lg-end float-lg-end d-flex flex-lg-row-reverse' data-aos="fade-up" data-aos-duration="3000">
                                    <div className='icon' style={{marginLeft:'10px', marginRight:'10px'}}>
                                        <Image src={benefits1} alt="icon" style={{width:'40px',height:'40px'}}></Image>
                                    </div>  
                                    <div className='content'>
                                        <h4 className='mb-1'>Command capsule</h4>
                                    </div>
                                </div>
                                <div className='service text-lg-end float-lg-end d-flex flex-lg-row-reverse' data-aos="fade-up" data-aos-duration="2000">
                                    <div className='icon' style={{marginLeft:'10px', marginRight:'10px'}}>
                                        <Image src={benefits2} alt="icon" style={{width:'40px',height:'40px'}}></Image>
                                    </div>  
                                    <div className='content'>
                                        <h4 className='mb-1'>Personalized attention</h4>
                                    </div>
                                </div>
                                <div className='service text-lg-end float-lg-end d-flex flex-lg-row-reverse' data-aos="fade-up" data-aos-duration="1000">
                                    <div className='icon' style={{marginLeft:'10px', marginRight:'10px'}}>
                                        <Image src={benefits4} alt="icon" style={{width:'40px',height:'40px'}}></Image>
                                    </div>  
                                    <div className='content'>
                                        <h4 className='mb-1'>Ai-assisted rank booster app</h4>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} md={12} sm={12} className=''>
                            <div data-aos="zoom-in-up" data-aos-duration="3000" data-aos-anchor-placement="top-center">
                                <Image src={cpaFeature} alt='CPA image' className='cpa_main_img mx-auto d-block' style={{padding:'15px',margin:'15px 0px'}} fluid></Image>
                            </div>
                        </Col>
                        <Col lg={4} md={12} sm={12} className=''>
                            <div>
                                <div className='service' data-aos="fade-up" data-aos-duration="3000">
                                    <div className='icon' style={{marginLeft:'10px', marginRight:'10px'}}>
                                        <Image src={benefits3} alt="icon" style={{width:'40px',height:'40px'}}></Image>
                                    </div>   
                                    <div className='content'>
                                        <h4 className='mb-1'>Rank booster</h4>
                                    </div>
                                </div>
                                <div className='service' data-aos="fade-up" data-aos-duration="2000">
                                    <div className='icon' style={{marginLeft:'10px', marginRight:'10px'}}>
                                        <Image src={benefits5} alt="icon" style={{width:'40px',height:'40px'}}></Image>
                                    </div>  
                                    <div className='content'>
                                        <h4 className='mb-1'>Doubt clearance</h4>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section  className="mt-50 custom_sec bg_light_gray" data-aos="fade-up" data-aos-duration="3000" style={{marginBottom:'0px'}}>
                <Container className='text-center'>
                    <h2 className='subtitle mb-3'>Want to Boost Your Career -  Apply Now!</h2>
                    <h4 className='text-center mb-4 center_text'>"We understand your challenges. Let us help you overcome them with tailored support and financial aid that brings your CPA dream within reach."</h4>
                    <Button className="theme_btn" href="https://docs.google.com/forms/d/e/1FAIpQLSfZXyQm_XnGGLZlQI9Ls-2CGYFokzMFINE82KajD5i-IKNeFw/viewform?embedded=true" target='_blank'>Claim Your Spot</Button>
                </Container>
            </section>
        </>
    );
};

export default IcadCpa;