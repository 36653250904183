import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import top1 from '../assets/images/icons/top-footer-icons/1.png';
import top2 from '../assets/images/icons/top-footer-icons/2.png';
import top3 from '../assets/images/icons/top-footer-icons/3.png';
import top4 from '../assets/images/icons/top-footer-icons/4.png';
import top5 from '../assets/images/icons/top-footer-icons/5.png';
import top6 from '../assets/images/icons/top-footer-icons/6.png';
import top7 from '../assets/images/icons/top-footer-icons/7.png';
import top8 from '../assets/images/icons/top-footer-icons/8.png';
import top9 from '../assets/images/icons/top-footer-icons/9.png';
import top10 from '../assets/images/icons/top-footer-icons/10.png';
import top11 from '../assets/images/icons/top-footer-icons/11.png';
import top12 from '../assets/images/icons/top-footer-icons/12.png';
import top13 from '../assets/images/icons/top-footer-icons/13.png';
import top14 from '../assets/images/icons/top-footer-icons/14.png';

var bgColors = { "Blue": "#00B1E1",
    "Cyan": "#37BC9B",
    "Green": "#8CC152",
    "Red": "#E9573F",
    "Yellow": "#F6BB42",
    "Purple": "#7952b3",
    "Pink": "#ff0080",
    "Orange": "#ff4000",
  };


const TopFooter = props => {
  return (
    <section className='top_footer mt-50'>
      <Container>
        <Row className='justify-content-center'>
          <Col style={{backgroundColor: bgColors.Blue}}>
            <a href="/about">
              <img src={top1} alt="top footer 1" />
              <h3>About Us</h3>
            </a>
          </Col>
          <Col style={{backgroundColor: bgColors.Red}}>
            <a href="/engineering">
              <img src={top2} alt="top footer 2" />
              <h3>Courses</h3>
            </a>
          </Col>
          <Col style={{backgroundColor: bgColors.Yellow}}>
            <a href="/icad-stars">
              <img src={top3} alt="top footer 1" />
              <h3>ICAD Stars</h3>
            </a>
          </Col>
          <Col style={{backgroundColor: bgColors.Cyan}}>
            <a href="/about-academics">
              <img src={top4} alt="top footer 1" />
              <h3>About Academics</h3>
            </a>
          </Col>
          <Col style={{backgroundColor: bgColors.Green}}>
            <a href="/results">
              <img src={top5} alt="top footer 1" />
              <h3>ICAD Results</h3>
            </a>
          </Col>
          <Col style={{backgroundColor: bgColors.Purple}}>
            <a href="/admission">
              <img src={top6} alt="top footer 1" />
              <h3>Admissions</h3>
            </a>
          </Col>
          <Col style={{backgroundColor: bgColors.Orange}}>
            <a href="/know-icadians">
              <img src={top7} alt="top footer 1" />
              <h3>Know Icadians</h3>
            </a>
          </Col>
       
          <Col style={{backgroundColor: bgColors.Red}}>
            <a href="/study-centers">
              <img src={top8} alt="top footer 1" />
              <h3>Study Centers</h3>
            </a>
          </Col>
          <Col style={{backgroundColor: bgColors.Cyan}}>
            <a href="/downloads">
              <img src={top9} alt="top footer 2" />
              <h3>Downloads</h3>
            </a>
          </Col>
          <Col style={{backgroundColor: bgColors.Pink}}>
            <a href="/gallery">
              <img src={top10} alt="top footer 1" />
              <h3>Gallery</h3>
            </a>
          </Col>
          <Col style={{backgroundColor: bgColors.Green}}>
            <a href="">
              <img src={top11} alt="top footer 1" />
              <h3>ICAD Digital</h3>
            </a>
          </Col>
          <Col style={{backgroundColor: bgColors.Orange}}>
            <a href="/icad-alumni">
              <img src={top12} alt="top footer 1" />
              <h3>ICAD Almuni</h3>
            </a>
          </Col>
          <Col style={{backgroundColor: bgColors.Cyan}}>
            <a href="https://www.youtube.com/channel/UCm2p1O0DA9Xgv18pUe7Juew" target='_blank'>
              <img src={top13} alt="top footer 1" />
              <h3>You Tube Videos</h3>
            </a>
          </Col>
          <Col style={{backgroundColor: bgColors.Yellow}}>
            <a href="/blog">
              <img src={top14} alt="top footer 1" />
              <h3>Blogs</h3>
            </a>
          </Col>
        </Row>   
      </Container>
    </section>
  );
};

export default TopFooter;